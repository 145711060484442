<script>
import { defineComponent } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import CommHubOfferVideo from '../templates/default/views/retailer/components/offers/community-hub-offer-video.vue'

export default defineComponent({
  name: 'community-hub-retailer-offer-video-play',
  components: {
    CommHubOfferVideo,
    BaseButton,
    BaseMdl,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      modal,
    }
  },
})
</script>

<template>
  <base-mdl width="lg" :layout="false" transparent>
    <div class="relative h-full w-full">
      <div
        class="absolute right-0 top-0 z-1 mr-2.5 mt-2.5 rounded-full bg-white"
      >
        <base-button icon="plain/close" class="p-[5px]" @click="modal.hide()" />
      </div>
      <comm-hub-offer-video :url="url" class="h-full" />
    </div>
  </base-mdl>
</template>
